import { Controller } from "stimulus"
export default class extends Controller {
  static targets = [
    'actionComponent'
  ]

  sendRequest() {
    const request = this.actionComponentTarget.dataset.request
    if(!('confirmMessage' in this.actionComponentTarget.dataset) || window.confirm(this.actionComponentTarget.dataset.confirmMessage))
    {
      if ('redirect' in this.actionComponentTarget.dataset) {
        if (MobileInterface.get()) {
          window.location.href = request;
        } else {
          window.open(request, '_blank');
        }
      }
      else {
        xhrSend(this.actionComponentTarget.dataset.method, request,
        () => {
          this.actionComponentTarget.dispatchEvent(new CustomEvent('update'))
        },
        () => { console.log("error in action-component stimulus")})
      }
    }
  }
}
